import { toRaw, isRef } from 'vue';

type ReactiveObjectType = Record<string, any>;

export const unwrapReactive = <T>(obj: T): T => {
  if (isRef(obj)) {
    // Unwrap the ref value
    return unwrapReactive(obj.value) as unknown as T;
  }
  if (Array.isArray(obj)) {
    return obj.map(item => unwrapReactive(item)) as unknown as T;
  }
  if (obj && typeof obj === 'object') {
    const rawObject = toRaw(obj) as ReactiveObjectType;
    const output: ReactiveObjectType = {};
    for (const [key, value] of Object.entries(rawObject)) {
      output[key] = unwrapReactive(value);
    }
    return output as T;
  }
  return obj;
}
